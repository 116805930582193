import { Modal as AMOdal, Col, Row, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { Avatar } from './Avatar';
import { Box } from '../Box/Box';
import CompanyFormContainer from './CompanyFormContainer';
import Edit from './Edit.svg';
import Form from '../Form/Form';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal/Modal';
import TrackingButton from 'components/TrackingComponents/Button';
import { getPermissionsActive } from 'utils/permissionMap';
import messages from 'containers/CompanyInfoPage/messages';
import styled from 'styled-components';
import translations from 'translations';
import formatFullAddress from 'utils/formatFullAddress';

const SETUP_ACCOUNT_PREMISSION = ['BUYER_FEATURE_TOGGLE', 'BUYER_INFO_EDIT'];
const Side = styled(Box)``;
const Content = styled(Box)``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4pt;
  gap: 4px;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.54);
  flex: 1;
`;

const Value = styled.span`
  flex: 2;
`;

const GroupHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 6pt;
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: middle;
`;

const SubText = styled.p`
  color: #bfbfbf;
  font-weight: 400;
`;

const CompanyForm = Form.create({ name: 'CompanyForm' })(CompanyFormContainer);

interface Props {
  company: any;
  goToPendingPage: () => void;
  countPending: number;
  permissions: any;
  onUpdateCompanyInfo: (data: any) => void;
  onSetFeatureFlag: (data: any) => void;
  vaInfo: any;
}

const Company: React.FC<Props> = ({
  company,
  permissions,
  onUpdateCompanyInfo,
  onSetFeatureFlag,
  countPending,
  goToPendingPage,
  vaInfo,
}) => {
  const formRef = useRef();
  const [isOpenEditModal, onOpenEditModal] = useState(false);
  const [enableOrderApprovalFunction, onEnableOrderApprovalFunction] = useState(
    (company.enabledFeatures || []).indexOf('ORDER_APPROVAL') > -1,
  );
  const [avatarUrl, setAvatarUrl] = useState(company.imageUrl || '');
  // tslint:disable jsx-no-lambda
  const onCloseEditModal = () => onOpenEditModal(false);

  const bankAccount = {
    accountName: vaInfo?.vaName || 'CÔNG TY TNHH KAMEREO',
    accountNumber: vaInfo.vaAccountNumber || '137483',
    bank: 'NGÂN HÀNG PHƯƠNG ĐÔNG (OCB) - CN TP.HCM',
  };

  const onSubmit = (data: any) => {
    data.imageUrl = avatarUrl;
    onUpdateCompanyInfo(data);
    onOpenEditModal(false);
  };

  const setOrderApproval = (status) => {
    if (countPending && !status) {
      return AMOdal.confirm({
        autoFocusButton: null,
        title: translations(messages.orderApprovalFuncModalTitle),
        content: translations(messages.orderApprovalFuncModalDecs),
        okText: translations(messages.goToButton),
        cancelText: translations(messages.cancel),
        onOk: () => {
          goToPendingPage();
        },
      });
    }
    onSetFeatureFlag({ buyerId: company.id, enabled: status });
    return onEnableOrderApprovalFunction(status);
  };

  const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;

  useEffect(() => {
    setAvatarUrl(company.imageUrl);
    onEnableOrderApprovalFunction((company.enabledFeatures || []).indexOf('ORDER_APPROVAL') > -1);
  }, [company]);

  const canSetupAccount = getPermissionsActive(SETUP_ACCOUNT_PREMISSION, permissions);

  return (
    <Row gutter={8}>
      <Col lg={11} xl={8} xs={24}>
        <Side>
          <Avatar src={company.imageUrl || alternativeImage(company.name)} disabled={true} />
          <InfoContainer>
            <Heading>
              <FormattedMessage {...messages.companyInfo} />
            </Heading>
            <Info>
              <Label>
                <FormattedMessage {...messages.name} />
              </Label>
              <Value>{company.name}</Value>
            </Info>
            <Info>
              <Label>
                <FormattedMessage {...messages.address} />
              </Label>
              <Value>{formatFullAddress(company?.location)}</Value>
            </Info>
            <Info>
              <Label>
                <FormattedMessage {...messages.phone} />
              </Label>
              <Value>{company.telephone}</Value>
            </Info>
          </InfoContainer>
          <InfoContainer>
            <Heading>
              <FormattedMessage {...messages.redInvoiceInfo} />
            </Heading>
            <Info>
              <Label>
                <FormattedMessage {...messages.companyName} />
              </Label>
              <Value>{company.redInvoiceInfo.companyName}</Value>
            </Info>
            <Info>
              <Label>
                <FormattedMessage {...messages.address} />
              </Label>
              <Value>{formatFullAddress(company?.redInvoiceInfo?.location)}</Value>
            </Info>
            <Info>
              <Label>
                <FormattedMessage {...messages.taxCode} />
              </Label>
              <Value>{company.redInvoiceInfo.mst}</Value>
            </Info>
          </InfoContainer>
          {vaInfo.vaAccountNumber && (
            <InfoContainer>
              <Heading>
                <FormattedMessage {...messages.vaAccountInformation} />
              </Heading>
              <Info>
                <Label>
                  <FormattedMessage {...messages.vaAccount} />
                </Label>
                <Value>{bankAccount.accountNumber}</Value>
              </Info>
              <Info>
                <Label>
                  <FormattedMessage {...messages.vaAccountName} />
                </Label>
                <Value>{bankAccount.accountName}</Value>
              </Info>
            </InfoContainer>
          )}
          {canSetupAccount && false && (
            <TrackingButton
              trackingCategory="Company | Button"
              trackingAction="Edit Company"
              type="secondary"
              block
              onClick={() => onOpenEditModal(true)}
            >
              <FormattedMessage {...messages.editInfo} />
            </TrackingButton>
          )}
        </Side>
      </Col>
      <Col lg={13} xl={16} xs={24}>
        <Content>
          <GroupHeading>
            <Heading>
              <FormattedMessage {...messages.orderApprovalFunc} />
            </Heading>
          </GroupHeading>
          <ActionItem>
            <div>
              {enableOrderApprovalFunction ? (
                <FormattedMessage {...messages.active} />
              ) : (
                <FormattedMessage {...messages.inactive} />
              )}
              <SubText>
                <FormattedMessage {...messages.orderApprovalFuncDes} />
              </SubText>
            </div>
            {canSetupAccount && (
              <Switch
                checked={enableOrderApprovalFunction}
                onChange={() => setOrderApproval(!enableOrderApprovalFunction)}
              />
            )}
          </ActionItem>
        </Content>
      </Col>
      <FormattedMessage {...messages.editCompany}>
        {(editCompany) => (
          <Modal size="large" headerLabel={editCompany as any} headerIcon={Edit} isOpen={isOpenEditModal}>
            <Row gutter={8}>
              <Col lg={8} xs={24}>
                <Heading>
                  <FormattedMessage {...messages.companyImage} />
                </Heading>
                <Avatar src={avatarUrl} onUploadImageSuccess={setAvatarUrl} />
              </Col>
              <Col lg={16} xs={24}>
                {isOpenEditModal ? (
                  <CompanyForm
                    company={company}
                    onCancel={onCloseEditModal}
                    onSubmit={onSubmit}
                    wrappedComponentRef={formRef}
                  />
                ) : null}
              </Col>
            </Row>
          </Modal>
        )}
      </FormattedMessage>
    </Row>
  );
};

const CompanyWrapper: React.FC<Props> = (props) => {
  if (props.company.loading) {
    return null;
  }
  return <Company {...props} />;
};

export default CompanyWrapper;

import { isEmpty } from 'lodash';
import { Location, Maybe } from '../types/schema';
export default (location?: Maybe<Location> | undefined) => {
  if (location && !isEmpty(location)) {
    const addressOrder = ['noteForAddress', 'address', 'ward', 'district', 'city'];
    return addressOrder
      .reduce((acc, item) => {
        if (location?.[item]) {
          if (item === 'noteForAddress' && !!location?.[item]?.trim()) {
            location[item] = `${location?.[item].replace(/[()]/g, '')}`;
          }
          if (location?.[item].trim() === '') {
            return acc;
          }
          acc.push(location?.[item]);
        }
        return acc;
      }, [] as string[])
      .join(', ');
  }
  return '....';
};

import { Col, Row } from 'antd';
import React, { Component, Fragment } from 'react';

import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import Image from '../Image/Image';
import Input from 'components/TrackingComponents/Input';
import messages from 'containers/CompanyInfoPage/messages';
import { signImageUpload } from 'utils/apis';
import styled, { CSSProp } from 'styled-components';

const AvatarWrapper = styled.div<any>`
  margin-bottom: 6pt;
  padding: 2px;
  border: ${({ highlighDropable }) => (highlighDropable ? `1px dashed blue` : 'none')};
  background: ${({ highlighInDropzone }) => (highlighInDropzone ? `red` : 'initial')};

  ${({ customAvatarStyle }) => customAvatarStyle}
`;

const DefaultImageControls = styled.div`
  ${Button} {
    padding: 0;
  }
`;

export const InputOverplay = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
`;

const ImageUploadButton = ({ onFileDrop }) => (
  <DefaultImageControls>
    <Row gutter={8}>
      <Col span={12} />
      <Col span={12}>
        <Button block>
          <FormattedMessage {...messages.uploadImage} />
        </Button>
        <Input
          InputComponent={InputOverplay}
          trackingCategory="InputOverplay"
          trackingAction="Upload Image"
          onChange={onFileDrop}
          type="file"
          name="pic"
          accept="image/*"
        />
      </Col>
    </Row>
  </DefaultImageControls>
);

export class Avatar extends Component<{
  src?: string;
  disabled?: boolean;
  highlight?: boolean;
  onUploadImageSuccess?: (url: string) => void;
  ImageControlsComponent?: ({ onFileDrop }: { onFileDrop: any }) => JSX.Element;
  customAvatarStyle?: CSSProp;
}> {
  public state = {
    dragging: false,
    inZone: false,
  };
  public onDragEnter = (e) => {
    this.setState({ inZone: true });
  };
  public onDragStart = (e) => {
    this.setState({ dragging: true });
  };
  public onDragEnd = (e) => {
    this.setState({ dragging: false });
  };
  public onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ inZone: true });
    return false;
  };
  public onDragLeave = (e) => {
    this.setState({ inZone: false });
  };
  public onFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dt = e.dataTransfer || e.target;
    const files = dt.files;
    if (files.length > 1) {
      alert('Only allow 1 file upload!');
      return;
    }
    for (const file of files) {
      const reader = new FileReader();
      reader.addEventListener('loadend', async (e) => {
        const response: any = await signImageUpload({
          resourceGroup: 'LOGO',
          contentType: file.type,
          fileName: file.name,
        });
        if (response.success) {
          const { uploadUrl, fullUrl } = response;
          if (!reader.result) {
            return Promise.reject();
          }
          return fetch(uploadUrl, {
            method: 'PUT',
            mode: 'cors',
            body: new Blob([reader.result], { type: file.type }),
          }).then(() => {
            if (this.props.onUploadImageSuccess) {
              this.props.onUploadImageSuccess(fullUrl);
            }
          });
        }
        return Promise.reject();
      });
      reader.readAsArrayBuffer(file);
    }
    this.setState({ inZone: false, dragging: false });
    return false;
  };
  public render() {
    const { ImageControlsComponent } = this.props;
    const ImageControls = ImageControlsComponent || ImageUploadButton;
    if (this.props.disabled) {
      return (
        <AvatarWrapper>
          <Image src={this.props.src} />
        </AvatarWrapper>
      );
    }
    return (
      <Fragment>
        <AvatarWrapper
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
          onDrop={this.onFileDrop}
          highlighDropable={this.props.highlight ? true : this.state.dragging}
          highlighInDropzone={this.state.inZone}
          customAvatarStyle={this.props.customAvatarStyle}
        >
          {!this.props.highlight && <Image src={this.props.src} />}
        </AvatarWrapper>
        <ImageControls onFileDrop={this.onFileDrop} />
      </Fragment>
    );
  }
}
